<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section  section-gap-30">

                <SettingMenu></SettingMenu>

                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{$t(`WP App Key`)}}
                            <v-popover offset="10" :disabled="false"  style="display:initial;">
                                <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/generate-app-key/" target="_blank">
                                            {{ $t(`How to generate app key?`) }}
                                        </a> <br/>
                                        <a href="https://easy.jobs/docs/easyjobs-wordpress" target="_blank">
                                            {{ $t(`Connect easy.jobs with your WordPress.`) }}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>

                        <a href="#" v-if="showForm" class="button danger-button" @click.prevent="hide">{{$t(`Cancel`)}}</a>
                        <a href="#" v-else  class="button primary-button text-capitalize" @click.prevent="show">
                            <span class="icon"><i class="eicon e-plus"></i></span>
                            <span class="text-capitalize">{{$t(`Generate New App Key`)}}</span>
                        </a>
                    </div>
                    <div class="data-table app-key-table" v-if="isContentLoading">
                        <TableLoader></TableLoader>
                    </div>

                    <template v-else>
                        <div v-if="showForm" class="form-box">
                            <div class="section-title-wrap margin-deliver">
                                <div class="section-title">{{$t(`Generate App Key`)}}</div>
                            </div>
                            <form action="">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"
                                                   ref="key_label"
                                                   id="key_label"
                                                   class="form-control"
                                                    :placeholder="$t(`Write app key label`) "
                                                    v-model="form.label"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4">

                                        <submit-button :click="generateKey" :loading="isLoading"> {{$t(`Generate`)}} </submit-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- data table -->
                        <div class="data-table app-key-table" v-else>
                            <h4 class="empty-message" v-if="isEmpty">{{$t(`No App Key Found`)}}</h4>
                            <div class="table-wrap" v-if="!isEmpty">
                                <div class="table">
                                    <div class="table__row table__head">
                                        <div class="table-cell text-center">{{$t(`No`)}}</div>
                                        <div class="table-cell text-center">{{$t(`Label`)}}</div>
                                        <div class="table-cell">{{$t(`App Key`)}}</div>
                                        <div class="table-cell text-center">{{$t(`Created at`)}}</div>
                                        <div class="table-cell text-center">{{$t(`Actions`)}}</div>
                                    </div>
                                    <div class="table__row" v-for="(appKey, key) in appKeys" :key="key">
                                        <div class="table-cell key-counter" v-text="(key + 1)"></div>
                                        <div class="table-cell key-code" v-text="appKey.label"></div>
                                        <div class="table-cell key-code copy-url" @click="copyKey(appKey.key)" v-text="appKey.key"></div>
                                        <div class="table-cell key-creation-time" v-text="appKey.created_at"></div>
                                        <div class="table-cell key-action">
                                            <a href="javascript:void(0)" @click.prevent="deleteKey(appKey.id,key)" class="control-button control-button--danger has_bg_color">
                                                <div class="control-button__icon">
                                                    <i class="eicon e-delete"></i>
                                                </div>
                                                <span>{{$t(`Delete`)}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "../../../layouts/DashboardLayout";
    import SettingMenu from "../../../components/company/SettingSidebar";
    import client from "../../../app/api/Client";
    import TableLoader from "../../../components/_loaders/_TableLoader";
    export default {
        components: {
            DashboardLayout,
            SettingMenu,
            TableLoader
        },
        data() {
            return {
                isContentLoading: false,
                appKeys: [],
                form : {
                    label : ''
                },
                showForm: false,
                isLoading: false
            }
        },

        computed: {
            isEmpty() {
                return this.appKeys.length === 0;
            },
        },
        methods : {
            show(){
                this.showForm = true;
                this.$nextTick(() => {
                    const editButtonRef = this.$refs.key_label;
                    editButtonRef.focus();
                });
            },
            hide(){
                this.showForm = false;
                this.form.label = '';
            },
            async getAppKeys() {
                this.isContentLoading = true;
                try{
                    let {data : {data}} = await client().get('/company/setting/key');
                    this.appKeys = data;
                } catch (e) {
                }
                this.isContentLoading = false;
            },
            generateKey() {
                if(this.form.label.length <1 ) {
                    this.$toast.error(this.$t(`Please write a label`));
                    return false;
                }
                this.isLoading = true;
                client().post('/company/setting/key/create', this.form).then(({data : { data, message }}) => {
                    this.appKeys.push(data);
                    this.$toast.success(this.$t(message));
                    this.copyKey(data.key);
                    this.hide();
                }).catch(({response : { data }}) => {
                    this.$toast.error(this.$t(data.message));
                }).finally(res => {
                    this.isLoading = false;
                });
            },
            deleteKey(keyId, index) {
                let message ={
                    title : this.$t('confirmation'),
                    body : this.$t("Are you sure, you want to delete this app key?")
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };
                this.$dialog.confirm(message, dialogConfig).then(() => {
                    client().delete(`/company/setting/key/${keyId}/delete`).then(({data : { data, message }}) => {
                        this.appKeys.splice(index,1);
                        this.$toast.success(this.$t(message));
                    }).catch(({response : { data }}) => {
                        this.$toast.error(this.$t(data.message));
                    });
                });
            },
            copyKey(key) {
                _helper.copyText(key);
                this.$toast.success(this.$t(`Copied`));
            },
        },
        async created() {
            await this.getAppKeys();
        }
    }
</script>
<style scoped>
    .copy-url {
        cursor: pointer;
    }

</style>